<template>
  <div class="content">
    <div class="header">
      <img
        @click="goback"
        class="back"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
        alt=""
      />
      <div class="title">
        {{
          queryID === '1'
            ? '警灯控制'
            : queryID === '2'
            ? '货箱控制'
            : queryID === '3'
            ? '远程喊话'
            : queryID === '5'
            ? '侧屏控制'
            : ''
        }}
      </div>
    </div>

    <!-- 警灯控制 -->
    <div class="top" v-if="queryID === '1'">
      <img
        class="top-bg"
        src="../../assets/securityVehicle/alarm-lamp-top.png"
        alt=""
      />
      <div class="on-off">
        <div class="btn" @click="warningControl(1)">
          <img
            v-if="!warningFlagStart"
            src="../../assets/securityVehicle/start-d.png"
            alt=""
          />
          <img
            v-if="warningFlagStart"
            src="../../assets/securityVehicle/start-t.png"
            alt=""
          />
          <p>开启</p>
        </div>
        <div class="btn" @click="warningControl(0)">
          <img
            v-if="!warningFlagClose"
            src="../../assets/securityVehicle/close-d.png"
            alt=""
          />
          <img
            v-if="warningFlagClose"
            src="../../assets/securityVehicle/close-t.png"
            alt=""
          />
          <p>关闭</p>
        </div>
      </div>
    </div>

    <!-- 货箱控制 -->
    <div class="top" v-if="queryID === '2'">
      <img
        class="top-bg"
        src="../../assets/securityVehicle/container-top.png"
        alt=""
      />
      <div class="on-off">
        <div class="btn" @click="openDoor">
          <img
            v-if="!openDoorFlag"
            src="../../assets/securityVehicle/start-d.png"
            alt=""
          />
          <img
            v-if="openDoorFlag"
            src="../../assets/securityVehicle/start-t.png"
            alt=""
          />
          <p>开启</p>
        </div>
      </div>
    </div>

    <!-- 远程喊话 -->
    <div class="top" v-if="queryID === '3'">
      <img
        class="top-bg"
        src="../../assets/securityVehicle/voice-top.png"
        alt=""
      />
      <div class="on-off">
        <p class="des" v-if="!volumeFlag">请长按按键进行喊话，松开结束喊话</p>
        <div
          class="btn"
          @mousedown.prevent="handleEnterRoom"
          @mouseup="handleLeaveRoom"
          @touchstart.prevent="handleEnterRoom"
          @touchend="handleLeaveRoom"
        >
          <img
            v-if="!volumeFlag"
            src="../../assets/securityVehicle/volume-d.png"
            alt=""
          />
          <img
            v-if="volumeFlag"
            src="../../assets/securityVehicle/volume-t.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 侧屏控制 -->
    <div class="top" v-if="queryID === '5'">
      <img
        class="top-bg"
        src="../../assets/securityVehicle/side-screen-top.png"
        alt=""
      />
      <div class="range">
        <span class="range-text"> 音量 </span>
        <input
          class="range-input"
          type="range"
          name="range"
          min="0"
          max="100"
          step="1"
          :value="this.sideVolume"
          @change="this.changeSideVolume"
          :style="{ backgroundSize: sideVolume + '% 100%' }"
        />
      </div>

      <div class="on-off">
        <div class="btn" @click="sideControl(1)">
          <img
            v-if="!sideFlagStart"
            src="../../assets/securityVehicle/start-d.png"
            alt=""
          />
          <img
            v-if="sideFlagStart"
            src="../../assets/securityVehicle/start-t.png"
            alt=""
          />
          <p>开启</p>
        </div>
        <div class="btn" @click="sideControl(0)">
          <img
            v-if="!sideFlagClose"
            src="../../assets/securityVehicle/close-d.png"
            alt=""
          />
          <img
            v-if="sideFlagClose"
            src="../../assets/securityVehicle/close-t.png"
            alt=""
          />
          <p>关闭</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TRTC from 'trtc-sdk-v5';
import fetchSdatacenter from '@/utils/fetchSdatacenter';
import { Toast } from 'vant';
import { requestMicroPermission } from '@/utils';
import { sideScreenControl, sideVolumeControl } from '@/api/api';
import Vconsole from 'vconsole';

export default {
  name: 'SecurityVehicle',
  components: {},
  data() {
    return {
      queryID: '',
      vin: '',
      warningFlagStart: false,
      warningFlagClose: false,
      openDoorFlag: false,
      volumeFlag: false,
      timer: null,
      trtc: null,
      statusFlag: false, // 判断进来时获取麦克风是否拒绝
      sideFlagStart: false,
      sideFlagClose: false,
      sideVolume: 30,
    };
  },

  created: function () {
    this.queryID = this.$route.query.id;
    this.vin = this.$route.query.vin;

    window.microphonePermission = () => {
      this.statusFlag = true; // true获取麦克风权限
    };
  },

  mounted: function () {
    // new Vconsole();
    if (this.queryID === '3') {
      requestMicroPermission();
      this.trtc = TRTC.create();
    }
  },

  destroyed: function () {
    if (this.queryID === '3') {
      this.trtc.exitRoom(); // 退出房间
      // 若后续无需再使用该 trtc，则销毁 trtc，并释放引用。
      this.trtc.destroy();
      this.trtc = null;
    }
  },

  methods: {
    // 返回
    goback() {
      history.back();
    },

    // 警灯控制
    warningControl(warningLamp) {
      if (warningLamp === 1) {
        this.warningFlagStart = true;
        setTimeout(() => {
          this.warningFlagStart = false;
        }, 200);
      } else {
        this.warningFlagClose = true;
        setTimeout(() => {
          this.warningFlagClose = false;
        }, 200);
      }

      const formData = new FormData();
      formData.append('vin', this.vin);
      formData.append('warningLamp', warningLamp); // 开关 0：关闭，1：开启

      fetchSdatacenter('security/warningLampControl', {
        method: 'POST',
        data: formData,
      }).then((data) => {
        Toast({
          message: `${warningLamp === 0 ? '关闭成功' : '开启成功'}`,
          className: 'response-toast',
          forbidClick: true,
        });
      });
    },

    // 安防车开货箱门
    openDoor() {
      this.openDoorFlag = true;
      setTimeout(() => {
        this.openDoorFlag = false;
      }, 200);

      const formData = new FormData();
      formData.append('vin', this.vin);

      fetchSdatacenter('security/openDoor', {
        method: 'POST',
        data: formData,
      }).then((data) => {
        Toast({
          message: '开启成功',
          className: 'response-toast',
          forbidClick: true,
        });
      });
    },

    // 语音通话
    voiceCall() {
      const formData = new FormData();
      formData.append('vin', this.vin);

      fetchSdatacenter('security/genSigWithVin', {
        method: 'POST',
        data: formData,
      }).then((data) => {
        if (!this.statusFlag) {
          requestMicroPermission();
          this.volumeFlag = false;
          return;
        }
        this.trtc.enterRoom({
          roomId: data.data.roomId,
          sdkAppId: data.data.sdkAppId,
          userId: data.data.userId,
          userSig: data.data.userSig,
        });

        this.trtc.startLocalAudio();
      });
    },

    // 长按远程通话
    handleEnterRoom() {
      this.timer = setTimeout(() => {
        this.timer = null;
        this.volumeFlag = true;
        this.voiceCall();
      }, 500);
    },
    // 松开按钮
    handleLeaveRoom() {
      clearTimeout(this.timer);
      this.timer = null;
      this.volumeFlag = false;
      this.trtc.stopLocalAudio();
      this.trtc.exitRoom();
    },

    // 侧屏控制
    sideControl(sideStatus) {
      if (sideStatus === 1) {
        this.sideFlagStart = true;
        setTimeout(() => {
          this.sideFlagStart = false;
        }, 200);
      } else {
        this.sideFlagClose = true;
        setTimeout(() => {
          this.sideFlagClose = false;
        }, 200);
      }

      sideScreenControl({
        vin: this.vin,
        screenControl: sideStatus, // 1:打开  0:关闭
      }).then((res) => {
        Toast({
          message: `${sideStatus === 0 ? '关闭成功' : '开启成功'}`,
          className: 'response-toast',
          forbidClick: true,
        });
      });
    },

    // 侧屏声音控制
    changeSideVolume(e) {
      this.sideVolume = e.target.value;
      sideVolumeControl({
        vin: this.vin,
        volume: this.sideVolume,
      }).then((res) => {
        Toast({
          message: '音量调节成功',
          className: 'response-toast',
          forbidClick: true,
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
* {
  -webkit-user-select: none; /*禁用手机浏览器的用户选择功能 */
  -moz-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none; /*IE10*/
  user-select: none;
  -khtml-user-select: none;
}
.content {
  font-size: initial;
  height: 100%;
  background: #ffffff;
  .header {
    height: 40px;
    line-height: 40px;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 100;
    .back {
      width: 32px;
      height: 32px;
      margin: 4px 11px;
    }
    .title {
      font-size: 15px;
      color: #000000;
      font-weight: 600;
      text-align: center;
      width: 100%;
      min-width: 100px;
      margin-right: 54px;
    }
  }

  .top {
    margin-top: 40px;
    .top-bg {
      width: 100%;
      height: 283px;
    }
    .on-off {
      position: fixed;
      bottom: 46px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .btn {
        img {
          width: 86px;
          height: 86px;
        }
        p {
          font-size: 18px;
          font-weight: 500;
          color: rgba(0, 0, 0, 1);
          margin-top: 9px;
          text-align: center;
        }
      }
      .des {
        position: absolute;
        left: 50%;
        top: -70px;
        transform: translateX(-50%);
        width: 296px;
        height: 48px;
        line-height: 48px;
        opacity: 1;
        border-radius: 6px;
        background: rgba(70, 95, 253, 1);
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        &::before {
          content: '';
          position: absolute;
          bottom: -9px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid rgba(70, 95, 253, 1);
        }
      }
    }
  }

  .range {
    margin: 50px 0;
    height: 25px;
    line-height: 25px;
    padding: 0 30px;
    display: flex;
    .range-text {
      padding-right: 30px;
      flex: 1;
    }
    .range-input {
      color: red;
    }
    input[type='range'] {
      margin-top: 9px; /*上部分的填充值*/
      outline: none;
      width: calc(100% - 66px);
      -webkit-appearance: none; /*清除系统默认样式*/
      background: -webkit-linear-gradient(#436eff, #436eff) no-repeat, #e3e6f0; /*背景颜色，俩个颜色分别对应上下，自己尝试下就知道了嗯*/
      height: 5px; /*横条的高度，细的真的比较好看嗯*/
      border-radius: 6px;
    }
    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none; /*清除系统默认样式*/
      height: 21px; /*拖动块高度*/
      width: 21px; /*拖动块宽度*/
      background: #436eff;
      border-radius: 50%; /*外观设置为圆形*/
    }
  }
}
</style>
